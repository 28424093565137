import React, { useEffect } from "react"

import PageHeader from "../components/pageheader"
import Layout from "../components/layout"
import Seo from "../components/seo"
import hero from "../images/404-hero.jpg"
import * as styles from "../styles/contact.module.css"

const ProfilePage = () => {
    const title = "Profile"
    useEffect(() => {
        setTimeout(() => {
            if (window.gigya) {
                window.gigya.accounts.showScreenSet({
                    screenSet: "SML-ProfileUpdate",
                    startScreen: "gigya-update-profile-screen",
                    containerID: "screen",
                    onAfterScreenLoad: eventObj => {
                        // To rearange date of birth field in profile form
                        const birthMonth =
                            window.document.getElementsByClassName("birthMonth")
                        const birthYear =
                            window.document.getElementsByClassName("birthYear")
                        for (const month of birthMonth) {
                            month.style = "margin-top:-89px"
                        }
                        for (const year of birthYear) {
                            year.style = "margin-top:-89px"
                        }
                    },
                })
            }
        }, 1000)
    }, [])

    return (
        <Layout isSap>
            <Seo title={title} />
            <PageHeader
                image={hero}
                breadcrumbs={[
                    { label: title, link: null },
                ]}
            >
                <div className="bg-white">
                    <div className={`mx-auto pt-4 pb-24 ${styles.wrap}`}>
                        <div id="screen"></div>
                    </div>
                </div>
            </PageHeader>
        </Layout>
    )
}

export default ProfilePage
